import {
  MessageAttachment,
  MESSAGE_ATTACHMENT_REFERENCE_TYPES,
  WORKFLOW_ACTIONS,
  WORKFLOW_TRIGGERS,
  WorkflowStepEventNameAndData,
} from '@typings/api-models';
import { FullWorkflowStep } from '@noodleApi/ApiDefinition/workflows';
import isAttachmentHidden from '@helpers/isAttachmentHidden';

function attachmentMessage(count: number, referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES): string {
  const messageTemplates: { [key in MESSAGE_ATTACHMENT_REFERENCE_TYPES]?: (c: number) => string } = {
    [MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST]: c =>
      c === 1 ? 'This form request must be completed before moving on.' : `These ${c} form requests must be completed before moving on`,
    [MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE]: c =>
      c === 1 ? 'This invoice must be paid before moving on.' : `These ${c} invoices must be paid before moving on`,
    [MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE]: () => 'These documents must be uploaded before moving on',
    [MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER]: () => 'These documents must be uploaded before moving on',
  };

  return messageTemplates[referenceType]?.(count) ?? `${count === 1 ? 'This task' : 'These tasks'} must be completed before moving on.`;
}

function stepNameCustomer({
  step,
  creatorPersonId,
  customerId,
  personId,
}: {
  step: FullWorkflowStep & WorkflowStepEventNameAndData;
  creatorPersonId: string | null;
  customerId: string;
  personId: string | null;
}): string {
  if (step?.eventName === WORKFLOW_ACTIONS.SEND_MESSAGE) {
    let attachments: Array<Pick<MessageAttachment, 'referenceType' | 'title'>> = [];
    if (step?.stepTaken) {
      attachments = (step.stepTaken.message?.attachments || [])?.filter(
        attachment =>
          !isAttachmentHidden({
            attachment,
            creatorPersonId,
            customerId,
            personId,
          }),
      );
    } else if (step?.data?.message?.attachments) {
      attachments = step.data.message.attachments;
    }

    if (attachments.length > 0) {
      const messageAttachmentTypes = attachments.map(attachment => attachment.referenceType);
      const uniqueMessageAttachmentTypes = Array.from(new Set(messageAttachmentTypes)) as MESSAGE_ATTACHMENT_REFERENCE_TYPES[];

      if (uniqueMessageAttachmentTypes.length === 1) {
        const {length} = attachments;
        return attachmentMessage(length, uniqueMessageAttachmentTypes[0]);
      } if (uniqueMessageAttachmentTypes.length > 1) {
        const totalTasksCount = attachments.length;
        return totalTasksCount === 1
          ? 'This task must be completed before moving on'
          : `These ${totalTasksCount} tasks must be completed before moving on`;
      }
    }

    return 'This step contains a message only';
  }
  if (step?.title) { // or should this be at the top?
    return step.title;
  }
  if (step?.eventName === WORKFLOW_ACTIONS.SEND_EMAIL) {
    return 'Send Email';
  }
  if (step?.eventName === WORKFLOW_ACTIONS.GENERATE_ALL_WORKFLOW_PDFS) {
    return 'Generate PDFs';
  }
  if (step?.eventName === WORKFLOW_ACTIONS.SEND_DOCUMENT_REQUEST) {
    return 'Send Document Request';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.CART_ABANDONED) {
    return 'Abandoned Cart';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.DOCUMENTS_UPLOADED) {
    return 'Document Upload Complete';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.PRODUCT_PURCHASED) {
    return 'On Purchase';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.UPCOMING_BOOKING) {
    return 'Upcoming Booking';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.INITIATE_CTA) {
    return step?.data?.CTA || 'Initiate';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.FORM_REQUEST_COMPLETED) {
    return 'Form Request Complete';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA) {
    return step?.data?.title || 'Initiate on UserWorkflow';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.INITIATE_THREAD) {
    return 'Initiate Automatically';
  }
  if (step?.eventName === WORKFLOW_TRIGGERS.PURCHASE_INITIATED) {
    return 'On Purchase Initiated';
  }
  return 'Workflow Step';
}

export default stepNameCustomer;
