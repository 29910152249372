import { m } from 'framer-motion';
import s from './UserImage.module.scss';
import Avatar from './Avatar';

type UserImageType = {
  url?: Parameters<typeof Avatar>[0]['url'];
  name?: string | null;
  size?: number;
  className?: string;
  hasBorder?: boolean;
  borderColor?: string;
  color?: string | null;
  isActive?: boolean;
};

const UserImage: React.FC<UserImageType> = ({ url, name, size = 32, className = '', hasBorder, borderColor, color, isActive }) => {
  const urlIsString = typeof url === 'string';

  const isAnonymous = name === 'Anonymous' || name === 'Contact' || name === 'You';

  return (
    <div
      className={`${s['user-image__wrapper']} ${className}`}
      style={{
        backgroundColor:
          (isAnonymous && 'var(--color-gray-25)')
          || color
          || (urlIsString && url.startsWith('#') && url)
          || (!url && 'var(--color-gray-25)')
          || 'transparent',
        boxShadow: (hasBorder && `0px 0px 0px 3px ${borderColor || 'var(--color-gray-0)'}`) || undefined,
        color: (isAnonymous && 'var(--color-gray-75)') || ((color || (urlIsString && url.startsWith('#'))) && 'var(--color-gray-0)') || 'var(--color-gray-75)',
        height: `${size}px`,
        minWidth: `${size}px`,
        overflow: ((!url && !name) || isAnonymous) && 'hidden' || undefined,
        width: `${size}px`,
      }}
      data-translate='false'
    >
      <Avatar url={url} name={isAnonymous ? '' : name} size={size} />
      {isActive && !isAnonymous && (
        <m.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 1, duration: 0.5, type: 'spring' } }} className={s.active} />
      )}
    </div>
  );
};

export default UserImage;
