const ALL_DAY_LIMIT = 8 * 60;

const priceDurationText = (price?: { sessionDuration?: number | null } | null): string => {
  if (!price || !price.sessionDuration) {
    return 'One time';
  }

  if (price.sessionDuration >= ALL_DAY_LIMIT) {
    return 'All Day';
  }
  return `${price.sessionDuration} minutes`;
};

export default priceDurationText;