type Props = {
  size: number;
  as: 'mb' | 'kb';
  sigFigs?: number;
};

const fileSize = ({ size, as, sigFigs = 0 }: Props): string => {
  if (as === 'kb') {
    return `${(size / 1024).toFixed(sigFigs)}kb`;
  }
  if (as === 'mb') {
    return `${(size / 1024 / 1024).toFixed(sigFigs)}mb`;
  }
  return '';
};

export default fileSize;
