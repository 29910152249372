import { Currency } from '@typings/graphql-models';

export type { default as SlateNode } from './SlateNode';
export type Id = string;

export type Asset = {
  id: Id;
  url: string;
  fileName: string;
  mimeType: string;
  youTubeLink: string;
  vimeoUrl: string;
};

export type Video = Asset;

export type RichText = {
  html: string;
};

export enum Roles {
  Admin = 'admin',
  Creator = 'creator',
  Customer = 'customer'
}

export enum Providers {
  Anvil = 'anvil',
  Typeform = 'typeform',
  Noodle = 'noodle'
}

export { Currency };
export const DEFAULT_CURRENCY = Currency.Usd;

export type ValueOf<T> = T[keyof T];
export type SlateAST = any; // eslint-disable-line @typescript-eslint/no-explicit-any