const formatCSL = (words: string[]): string => {
  if (words.length === 0) {
    return '';
  }
  if (words.length === 1) {
    return words[0];
  }
  if (words.length === 2) {
    return words.join(' and ');
  }
  const last = words.pop();
  return `${words.join(', ')} and ${last}`;
};

export default formatCSL;