import React, { Fragment, useState } from 'react';
import NextImage from 'next/legacy/image';
import NoImage from '@components/Icons/NoImage';
import MediaLoader from 'components/MediaLoader';
import classNames from 'classnames';

import s from './Image.module.scss';

type ImageTypes = { url: string } | string | undefined | null;
const getImageSrc = (image: ImageTypes): string | undefined | null => {
  if (!image) {
    return image;
  }
  return typeof image === 'string' ? image : image.url;
};

type PropsType = {
  alt?: string;
  image: ImageTypes;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  isStep?: boolean;
  onClick?: () => void | undefined;
  isSquare?: boolean;
  className?: string;
  isLarge?: boolean;
  isUserAvatar?: boolean;
  isSquareLarge?: boolean;
  isProductImage?: boolean;
  imagePlaceholder?: React.ReactNode;
  isProductSquaredIcon?: boolean;
  hasPlaceholder?: boolean;
  size?: number;
};

const Image: React.FC<PropsType> = ({
  alt = '',
  image,
  objectFit = 'cover',
  isStep = false,
  onClick = undefined,
  isSquare = false,
  className = '',
  isLarge = false,
  isUserAvatar = false,
  isSquareLarge = false,
  isProductImage = false,
  imagePlaceholder = undefined,
  isProductSquaredIcon = false,
  hasPlaceholder = true,
  size,
}) => {
  const [elementIsLoaded, setIsLoaded] = useState(false);

  const getStyles = (): React.CSSProperties => ({
    ...(isProductImage ? { height: '200px' } : {}),
    ...(isStep
      ? {
        borderRadius: '10px',
        cursor: 'pointer',
        flex: 1,
        height: '100%',
        maxHeight: '680px',
        minHeight: 56,
        width: '100%',
      }
      : {}),
  });

  const imgSrc = getImageSrc(image);

  const getImageContent = (): React.ReactNode => {
    if (imgSrc && onClick) {
      return (
        <button
          style={{
            backgroundColor: 'transparent',
            border: 0,
            height: '100%',
            opacity: !elementIsLoaded ? 0 : 1,
            padding: 0,
            position: 'relative',
            width: '100%',
          }}
          onClick={onClick}
        >
          <NextImage
            src={imgSrc}
            layout="fill"
            objectFit={objectFit}
            loading="lazy"
            onLoad={e => {
              if (e.currentTarget.src.indexOf('data:image/gif;base64') < 0) {
                setIsLoaded(true);
              }
            }}
            onError={() => setIsLoaded(true)}
            alt={alt}
            width={size || undefined}
            height={size || undefined}
            sizes={size && `${size}px` || undefined}
          />
        </button>
      );
    }

    if (imgSrc) {
      return (
        <span
          style={{
            backgroundColor: 'transparent',
            border: 0,
            height: '100%',
            opacity: !elementIsLoaded ? 0 : 1,
            padding: 0,
            position: 'relative',
            width: '100%',
          }}
        >
          <NextImage
            src={imgSrc}
            layout="fill"
            objectFit={objectFit}
            loading="lazy"
            onLoad={e => {
              // eslint-disable-next-line
              e.currentTarget.src.indexOf('data:image/gif;base64') < 0 && setIsLoaded(true);
            }}
            onError={() => setIsLoaded(true)}
            alt={alt}
            width={size || undefined}
            height={size || undefined}
            sizes={size && `${size}px` || undefined}
          />
        </span>
      );
    }

    return (
      <Fragment>
        {hasPlaceholder && (
          <Fragment>
            {imagePlaceholder || (
              <div className={s['custom-image__placeholder']}>
                <NoImage />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <div
      className={classNames(s['custom-image__wrapper'], className, {
        [s['custom-image__wrapper--step']]: isStep,
        [s['custom-image__wrapper--square']]: isSquare,
        [s['custom-image__wrapper--user-avatar']]: isUserAvatar,
        [s['custom-image__wrapper--user-avatar-large']]: isUserAvatar && isLarge,
        [s['custom-image__wrapper--product']]: isProductImage,
        [s['custom-image__wrapper--square-large']]: isSquareLarge,
        [s['custom-image__wrapper--product-squared-icon']]: isProductSquaredIcon,
      })}
      style={getStyles()}
    >
      {!elementIsLoaded && hasPlaceholder && <MediaLoader className={s['custom-image__loader']} message="Loading..." />}
      {getImageContent()}
    </div>
  );
};

export default Image;
