import { DEFAULT_CURRENCY } from '@typings/common';

const priceWithCurrency = (num: number, currency: string, hideSuffix?: boolean): string => {
  const ucCurrency = currency ? currency.toUpperCase() : currency;
  const formatter = new Intl.NumberFormat('en-US', {
    currency: ucCurrency,
    minimumFractionDigits: 2,
    style: 'currency',
  });
  let suffix = '';
  if (ucCurrency === DEFAULT_CURRENCY && !hideSuffix) {
    suffix = ` ${DEFAULT_CURRENCY}`;
  }
  return `${formatter.format(num)}${suffix}`;
};

export default priceWithCurrency;