import * as ApiModels from '@typings/api-models';
import isAttachmentHidden from '@helpers/isAttachmentHidden';
import attachmentType from './attachmentType';

type Attachment =
  & Pick<ApiModels.MessageAttachment, 'referenceType' | 'title'>
  & Parameters<typeof isAttachmentHidden>[0]['attachment'];

type Step =
  & ApiModels.WorkflowStepEventNameAndData
  & Pick<ApiModels.WorkflowStep, 'title' | 'data'> & {
    stepTaken: Pick<ApiModels.StepTaken, 'id'> & {
      message: Pick<ApiModels.Message, 'id'> & {
        attachments: Array<Attachment>;
      } | null;
    } | null;
  };

// @todo DRY up with ./triggerTitle?
const stepName = ({
  step,
  showTitles,
  creatorPersonId,
  customerId,
  personId,
}: {
  step: Step & ApiModels.WorkflowStepEventNameAndData;
  showTitles: boolean;
  creatorPersonId: string | null;
  customerId: string;
  personId: string | null;
}): string => {
  if (step?.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_MESSAGE) {
    let attachments: Array<Pick<ApiModels.MessageAttachment, 'referenceType' | 'title'>> = [];
    if (step?.stepTaken) {
      attachments = (step.stepTaken.message?.attachments || [])?.filter((attachment) => !isAttachmentHidden({
        attachment,
        creatorPersonId,
        customerId,
        personId,
      }));
    } else if (step?.data?.message?.attachments) {
      attachments = step.data.message.attachments;
    }

    if (attachments.length > 0) {
      const messageAttachmentTypes = attachments?.map(attachment => attachment.referenceType);
      const uniqueMessageAttachmentTypes = Array.from(new Set(messageAttachmentTypes));
      if (uniqueMessageAttachmentTypes.length === 1) {
        const length = attachments?.length || 0;
        // map attachment titles with commas
        const attachmentTitles = attachments?.map(attachment => attachment.title).join(', ');
        return `${length > 1 ? `${length} ` : ''}${attachmentType(attachments?.[0].referenceType as ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES)}${length > 1 ? 's' : ''}${
          showTitles ? `: ${attachmentTitles}` : ''
        }`;
      }
      if (uniqueMessageAttachmentTypes.length > 1) {
        // map the attachment types to their names
        const attachmentTypeNames = uniqueMessageAttachmentTypes.map(type => {
          const length = attachments?.filter(attachment => attachment.referenceType === type).length || 0;
          // map attachment titles with commas
          const attachmentTitles = attachments?.filter(attachment => attachment.referenceType === type).map(attachment => attachment.title).join(', ');
          return `${length > 1 ? `${length} ` : ''}${attachmentType(type) + (showTitles ? `: ${attachmentTitles}` : "")}${length > 1 ? 's' : ''}`;
        });
        // join the names with commas
        const attachmentTypeNamesString = attachmentTypeNames.join(', ');
        // split the string at the last comma
        const lastCommaIndex = attachmentTypeNamesString.lastIndexOf(',');
        const firstPart = attachmentTypeNamesString.slice(0, lastCommaIndex);
        const lastPart = attachmentTypeNamesString.slice(lastCommaIndex + 1);
        // return the string with an 'and' between the two parts
        return `${firstPart} and ${lastPart}`;
      }
    }
  }

  if (step.title) {
    return step.title;
  }

  if (step?.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_MESSAGE) {
    return 'This step contains a message only';
  }

  if (step?.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_EMAIL) {
    return 'Send Email';
  }
  if (step?.eventName === ApiModels.WORKFLOW_ACTIONS.GENERATE_ALL_WORKFLOW_PDFS) {
    return 'Generate PDFs';
  }
  if (step?.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_DOCUMENT_REQUEST) {
    return 'Send Document Request';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.CART_ABANDONED) {
    return 'Abandoned Cart';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.DOCUMENTS_UPLOADED) {
    return 'Document Upload Complete';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.PRODUCT_PURCHASED) {
    return 'On Purchase';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.UPCOMING_BOOKING) {
    return 'Upcoming Booking';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.INITIATE_CTA) {
    return step?.data?.title || step?.data?.CTA || 'Initiate';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.FORM_REQUEST_COMPLETED) {
    return 'Form Request Complete';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA) {
    return step?.data?.title || 'Initiate on UserWorkflow';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.INITIATE_THREAD) {
    return 'Initiate Automatically';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.PURCHASE_INITIATED) {
    return 'On Purchase Initiated';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.CUSTOM_TERMS_AGREED) {
    return 'On Custom Terms Agreement';
  }
  if (step?.eventName === ApiModels.WORKFLOW_TRIGGERS.ALL_DEPENDENCIES) {
    return 'All Attachments Completed';
  }
  return 'Workflow Step';
};

export default stepName;
