import { MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';

const attachmentType = (type: MESSAGE_ATTACHMENT_REFERENCE_TYPES): string => {
  switch (type) {
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE:
    return 'Document Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER:
    return 'Document Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING:
    return 'Booking Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING_PRICE:
    return 'Booking Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT:
    return 'Credit Report';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST:
    return 'Form Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE:
    return 'Form Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE:
    return 'Invoice Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST:
    return 'Invoice Request';
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS:
  case MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE:
    return 'Custom Terms Request';
  default:
    return 'Attachment';
  }
};

export default attachmentType;
