import { NoodleProductTypes } from '@typings/api-models';

const productType = (type: NoodleProductTypes): string => {
  if (type === NoodleProductTypes.InPersonSession) {
    return 'In Person Service';
  }
  if (type === NoodleProductTypes.Lite) {
    return 'Private Chat';
  }
  if (type === NoodleProductTypes.NoodleSaaS) {
    return 'Noodle SaaS';
  }
  if (type === NoodleProductTypes.OnlineSession) {
    return 'Remote Service';
  }
  if (type === NoodleProductTypes.ProductGroup) {
    return 'Product Group';
  }
  if (type === NoodleProductTypes.Subscription) {
    return 'Membership';
  }
  if (type === NoodleProductTypes.Handbook) {
    return 'Byte';
  }
  return type;
};

export default productType;
