import { StepType, WORKFLOW_TRIGGERS, WorkflowStep } from '@typings/api-models';

type ThisWorkflow = {
  steps: Pick<WorkflowStep, 'eventName' | 'type' | 'title'>[];
};

// @todo DRY up with ./stepName?
const triggerText = (workflow: ThisWorkflow): string => {
  const triggerStep = workflow.steps.find((step) => step.type === StepType.TRIGGER);
  if (triggerStep?.title) {
    return triggerStep.title;
  }
  if (triggerStep?.eventName === WORKFLOW_TRIGGERS.PRODUCT_PURCHASED) {
    return 'On Purchase';
  }
  if (triggerStep?.eventName === WORKFLOW_TRIGGERS.CART_ABANDONED) {
    return 'On Cart Abandoned';
  }
  if (triggerStep?.eventName === WORKFLOW_TRIGGERS.UPCOMING_BOOKING) {
    return 'On Upcoming Booking';
  }
  if (triggerStep?.eventName === WORKFLOW_TRIGGERS.INITIATE_CTA) {
    return 'Initiate on CTA';
  }
  return 'Add trigger';
};

export default triggerText;
