import FieldError from '@components/FieldError';
import FieldHelpText from '@components/FieldHelpText';
import Check from '@components/Icons/Check';
import CaretDown from '@components/Icons/CaretDown';
import { AnimatePresence, m } from 'framer-motion';
import { FC, KeyboardEvent, useEffect, useState, useRef, ReactNode, useMemo } from 'react';
import { checkValidity, Rule } from '@helpers/validation';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel';
import s from './CustomSelect.module.scss';

interface Option {
  key?: string;
  title: string | ReactNode;
  value: string;
  disabled?: boolean;
}

interface OptGroup {
  key?: string;
  title: string | ReactNode;
  options: Option[];
}

interface CustomSelectProps {
  options?: Array<Option>;
  optionsWithGroups?: Array<OptGroup>;
  onChange: (value: string, error: string | null) => void;
  value: string;
  label?: string;
  id: string;
  error?: string | null;
  helpText?: string;
  rule?: Rule;
  name?: string;
  disabled?: boolean;
  trigger?: ReactNode;
  native?: boolean;
  withFilter?: boolean;
  className?: string;
}

const flattenOptionsWithGroups = (optGroups: Array<OptGroup>): Option[] =>
  optGroups.reduce((acc, group) => {
    acc.push({ disabled: true, title: group.title, value: '' });
    group.options.forEach(option => acc.push(option));
    return acc;
  }, [] as Option[]);

const MotionCaret = m(CaretDown);

const CustomSelect: FC<CustomSelectProps> = ({
  options,
  optionsWithGroups,
  onChange,
  value,
  label,
  id,
  error,
  helpText,
  rule,
  name,
  disabled,
  trigger,
  native,
  withFilter,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [checkedIndex, setCheckedIndex] = useState(-1);
  const [filter, setFilter] = useState('');
  const flatOptions = useMemo(
    () => (optionsWithGroups && flattenOptionsWithGroups(optionsWithGroups)) || options || [],
    [optionsWithGroups, options],
  );
  const filteredOptions = useMemo(
    () => flatOptions.filter(o => o.title?.toString().toLowerCase().includes(filter.toLowerCase())),
    [flatOptions, filter],
  );
  const listRef = useRef<HTMLUListElement>(null);
  const labelId = `label-${label}`;

  useEffect(() => {
    const optionIndex = filteredOptions.findIndex(o => o.value === value);
    setSelectedIndex(optionIndex);
    setCheckedIndex(optionIndex);
  }, [isOpen, filteredOptions, value]);

  useEffect(() => {
    if (listRef.current && selectedIndex >= 0) {
      const optionElement = listRef.current.children[selectedIndex];
      if (optionElement) {
        const listRect = listRef.current.getBoundingClientRect();
        const optionRect = optionElement.getBoundingClientRect();
        if (optionRect.bottom > listRect.bottom) {
          listRef.current.scrollTop += optionRect.bottom - listRect.bottom;
        } else if (optionRect.top < listRect.top) {
          listRef.current.scrollTop -= listRect.top - optionRect.top;
        }
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (isOpen && listRef.current && checkedIndex >= 0) {
      const optionElements = listRef.current.querySelectorAll('li');
      if (optionElements.length > checkedIndex) {
        const optionElement = optionElements[checkedIndex];
        if (optionElement) {
          const listRect = listRef.current.getBoundingClientRect();
          const optionRect = optionElement.getBoundingClientRect();
          if (optionRect.bottom > listRect.bottom) {
            listRef.current.scrollTop += optionRect.bottom - listRect.bottom;
          } else if (optionRect.top < listRect.top) {
            listRef.current.scrollTop -= listRect.top - optionRect.top;
          }
        }
      }
    }
  }, [isOpen, checkedIndex]);

  const handleOptionSelect = (checkedValue: string): void => {
    const option = filteredOptions.find(o => o.value === checkedValue);
    if (!option || option.disabled) {
      return;
    }
    const optionIndex = filteredOptions.findIndex(o => o.value === checkedValue);
    setCheckedIndex(optionIndex);
    setIsOpen(false);
    setIsTouched(true);
    const { errorMessage: newErrorMessage } = checkValidity(checkedValue, rule);
    onChange(checkedValue, newErrorMessage);
  };

  const handleToggleDropdown = (): void => {
    setIsOpen(!isOpen);
    setIsTouched(false);
  };

  useEffect(() => {
    if (!isOpen) setSelectedIndex(-1);
    setFilter('');
  }, [isOpen]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
    case 'Escape':
      setIsOpen(false);
      break;
    case 'ArrowDown':
      event.preventDefault();
      if (filteredOptions.length > 0) {
        setSelectedIndex(prevIndex => {
          let nextIndex = prevIndex;
          do {
            nextIndex = (nextIndex + 1) % filteredOptions.length;
          } while (filteredOptions[nextIndex].disabled);
          return nextIndex;
        });
      }
      break;
    case 'ArrowUp':
      event.preventDefault();
      if (filteredOptions.length > 0) {
        setSelectedIndex(prevIndex => {
          let nextIndex = prevIndex;
          do {
            nextIndex = (nextIndex - 1 + filteredOptions.length) % filteredOptions.length;
          } while (filteredOptions[nextIndex].disabled);
          return nextIndex;
        });
      }
      break;
    case 'Enter':
      if (isOpen && selectedIndex > -1 && !filteredOptions[selectedIndex].disabled) {
        handleOptionSelect(filteredOptions[selectedIndex].value);
        event.preventDefault();
      }
      break;
    default:
      break;
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement | HTMLInputElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
      setIsTouched(true);
    }
  };

  if (native) {
    return (
      <div className={classNames(s.wrapper, className)}>
        {label && (
          <FieldLabel id={id} isValid={!error || !isTouched}>
            {label}
          </FieldLabel>
        )}
        {trigger}
        <select
          id={id}
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => handleOptionSelect(e.target.value)}
          className={classNames(s.nativeSelect, trigger && s.nativeSelectWithTrigger)}
        >
          {filteredOptions.map(option => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.title}
            </option>
          ))}
        </select>
        {error && isTouched && <FieldError className={s.error}>{error}</FieldError>}
        {helpText && !(isTouched && error) && <FieldHelpText>{helpText}</FieldHelpText>}
      </div>
    );
  }

  return (
    <div tabIndex={-1} onKeyDown={handleKeyDown} className={classNames(s.wrapper, className)} onBlur={handleBlur}>
      {label && (
        <FieldLabel id={id} labelId={labelId} isValid={!error || !isTouched}>
          {label}
        </FieldLabel>
      )}
      <button
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={disabled}
        aria-labelledby={labelId}
        name={name}
        type="button"
        onClick={handleToggleDropdown}
        className={s.button}
      >
        {trigger || (
          <div className={s.trigger}>
            {filteredOptions.find(o => o.value === value)?.title || 'Select...'}{' '}
            <MotionCaret
              animate={{
                rotate: isOpen ? 180 : 0,
                transition: { duration: 0.2 },
              }}
              color="var(--color-gray-75)"
              size={12}
              weight="bold"
            />
          </div>
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <m.div
            layout="size"
            className={s.listAndFilter}
            initial={{ opacity: 0, y: 8 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
              y: 0,
            }}
            exit={{ opacity: 0, transition: { duration: 0.1 }, y: 4 }}
          >
            {withFilter && (
              <m.input
                layout
                type="text"
                aria-label="Filter"
                placeholder="Filter..."
                onChange={e => setFilter(e.target.value)}
                onFocus={() => setIsOpen(true)}
                className={s.filter}
              />
            )}
            <m.ul layout role="list" className={s.list} ref={listRef}>
              {filteredOptions.length > 0
                ? filteredOptions.map((option, index) => (
                  <m.li
                    key={option.value + index}
                    onMouseEnter={() => !option.disabled && setSelectedIndex(index)}
                    role="option"
                    aria-selected={selectedIndex === index}
                    aria-checked={value === option.value}
                    aria-disabled={option.disabled}
                    onClick={() => handleOptionSelect(option.value)}
                    className={classNames(s.option, option.disabled && s.disabled)}
                    layout
                  >
                    {option.title}
                    {value === option.value && (
                      <Check color={typeof option.title === 'string' ? 'var(--color-primary)' : 'var(--color-gray-75)'} size={12} weight="bold" />
                    )}
                  </m.li>
                ))
                : <m.li layout className={classNames(s.option, s.disabled)}>No options</m.li>}
            </m.ul>
          </m.div>
        )}
      </AnimatePresence>
      {error && isTouched && <FieldError className={s.error}>{error}</FieldError>}
      {helpText && !(isTouched && error) && <FieldHelpText>{helpText}</FieldHelpText>}
    </div>
  );
};

export default CustomSelect;
