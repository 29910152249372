import Image from '@components/Image';
import User from '@components/Icons/User';
import nameToInitials from '@helpers/nameToInitials';

import s from './UserImage.module.scss';

type Props = {
  url?: Parameters<typeof Image>[0]['image'];
  name?: string | null;
  size: number;
};

const Avatar: React.FC<Props> = ({ url, name, size }) => {
  const urlIsString = typeof url === 'string';
  if (url && !(urlIsString && url.startsWith('#'))) {
    return <Image image={url} alt={name || undefined} objectFit="cover" size={size} />;
  }
  const initials = nameToInitials(name);

  return (
    <div style={{ transform: `scale(${size / 24})` }} className={s['user-image__letters']}>
      {initials || <User weight='fill' size={24} />}
    </div>
  );

};

export default Avatar;
