import * as ApiModels from '@typings/api-models';
import { logError } from '@providers/ErrorTracking';

const RECURRING_INTERVALS_ABBREVIATED = {
  DAY: 'dy',
  MONTH: 'mo',
  SIXMONTHS: '6 mo\'s',
  THREEMONTHS: '3 mo\'s',
  WEEK: 'wk',
  YEAR: 'yr',
};

const formatPriceInterval = (recurringInterval?: ApiModels.Interval | undefined | null): string => {
  if (!recurringInterval) {
    return '';
  }

  const intervalPart = RECURRING_INTERVALS_ABBREVIATED[recurringInterval];

  if (!intervalPart) {
    logError(new Error(`Unsupported recurringInteval: "${recurringInterval}"`));
    return '';
  }

  return `/${intervalPart}`;
};

export default formatPriceInterval;