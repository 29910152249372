import classNames from 'classnames';
import s from './FieldError.module.scss';

type Props = {
  className?: string;
};

const FieldError: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(s.wrapper, className)}>
    {children}
  </div>
);

export default FieldError;
