import * as ApiModels from '@typings/api-models';
import formatAmountWithCurrency from '../priceWithCurrency';
import formatPriceInterval from '../priceInterval';

type Props = {
  price: number;
  currency: ApiModels.Currency,
  recurringInterval?: ApiModels.Interval | null,
};

const formatRecurringPrice = (price: Props | null | undefined, discountedPrice?: number): string => {
  if (!price) {
    return '';
  }
  const pricePart = formatAmountWithCurrency(discountedPrice || price.price, price.currency);
  const intervalPart = formatPriceInterval(price.recurringInterval);
  return `${pricePart}${intervalPart}`;
};

export default formatRecurringPrice;
