type Input = {
  owner?: {
    id: string;
    name?: string | null
  } | null;
  user?: { id: string } | null
};

const messageOwnerName = ({ owner, user }: Input): string => {
  if (owner?.name) {
    return owner.name;
  }

  if (user?.id && owner?.id && (owner?.id === user?.id)) {
    return 'You';
  }

  return 'Anonymous';
};

export default messageOwnerName;
