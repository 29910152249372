import s from './FieldLabel.module.scss';

type Props = {
  className?: string;
  isValid?: boolean;
  id: string;
  labelId?: string;
};

const FieldLabel: React.FC<Props> = ({
  children, className, isValid = true, id, labelId,
}) => (
  <label
    id={labelId}
    htmlFor={id}
    className={[
      s['field-label'],
      ...(className ? [className] : []),
      ...(!isValid ? [s['field-label--error']] : []),
    ].join(' ')}
  >
    {children}
  </label>
);

export default FieldLabel;
