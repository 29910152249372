import { IDENTIFIERS } from '@helpers/urlsHelper';
import classNames from 'classnames';

import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@/providers/Auth';
import { LanguageCode, SUPPORTED_LANGUAGES } from '@/providers/TranslationProvider/constants';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import s from './LanguageSwitch.module.scss';
import CustomSelect from '../FormFields/CustomSelect';

type Props = {
  currentLanguage: LanguageCode;
  className?: string;
  onChange: (newLanguage: LanguageCode) => void;
};

const LanguageSwitch: React.FC<Props> = ({ currentLanguage, className, onChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { profile } = useContext(AuthContext);
  const router = useRouter();
  const enableTranslations = useFeatureFlag('enable-translations');

  useEffect(() => {
    setIsVisible(!profile?.preferredLanguage || router.pathname === IDENTIFIERS.USER_PROFILE_EDIT);
    // Only trigger when the profile.id changes
  }, [profile?.id, router.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!enableTranslations || !isVisible) {
    return null;
  }

  return (
    <div data-translate="false" className={s.contents}>
      <CustomSelect
        id="language-switch"
        value={currentLanguage}
        onChange={e => {
          onChange(e as LanguageCode);
        }}
        options={SUPPORTED_LANGUAGES.map(language => ({
          title: `${language.flag} ${language.label}`,
          value: language.code,
        }))}
        className={classNames(s.wrapper, className)}
      />
    </div>
  );
};

export default LanguageSwitch;
