import { format as formatDate, formatDistance, isToday, isTomorrow, isThisWeek } from 'date-fns';
import twoDigits from './twoDigits';

export const withTimezone = ({ datetime, timezone }: { datetime: string; timezone?: string | undefined | null }): string => (
  new Date(datetime).toLocaleDateString(
    'default',
    {
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      month: 'short',
      timeZone: timezone || undefined,
      timeZoneName: 'short',
    },
  )
);

export const withoutTimezone = ({ datetime }: { datetime: string; }): string => (
  new Date(datetime).toLocaleDateString(
    'default',
    {
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      month: 'short',
    },
  )
);

export const withoutTime = (time: string): string => formatDate(new Date(time), 'LLL do');

export const timeOnly = (time: string): string => formatDate(new Date(time), 'h:mm a');

function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes*60000);
}

export const durationWithoutTimezone = ({ datetime, durationInMinutes}: { datetime: string; durationInMinutes: number} ): string => {
  const startTime = new Date(datetime);
  const endTime = addMinutes(startTime, durationInMinutes);

  return `${timeOnly(startTime.toISOString())} — ${timeOnly(endTime.toISOString())}`;
};

export const withYear = (time: string): string => formatDate(new Date(time), 'LLLL do, yyyy');

export const abbreviated = (time: string): string => formatDate(new Date(time), 'MM/dd/yyyy');

export const abbreviatedTimezone = ({ timezone }: { timezone: string; }): string => new Date().toLocaleTimeString('en-us',{ timeZone: timezone, timeZoneName:'short' }).split(' ')[2];
export const unabridgeTimezone = ({ timezone }: { timezone: string; }): string => new Date().toLocaleTimeString('en-us',{ timeZone: timezone, timeZoneName:'long' })
  .split(' ')
  .slice(2)
  .join(" ");

export const dateString = (dateObject: Date): string => {
  const year = dateObject.getFullYear().toFixed(0);
  const month = twoDigits(dateObject.getMonth() + 1);
  const day = twoDigits(dateObject.getDate());
  return `${year}-${month}-${day}`;
};

export const timeSince = ({ date }: { date: Date | string }): string => {
  const dateObject = typeof date === 'string' ? new Date(date) : date;
  return formatDistance(dateObject, new Date());
};

export const dayOfWeek = (time: string): string => formatDate(new Date(time), 'EEEE');

export const monthDay = (time: string): string => formatDate(new Date(time), 'MMM do');

export const upcoming = (date: Date | string, timeZone?: string): string => {
  const dateObject = typeof date === 'string' ? new Date(date) : date;

  const convertedDate = timeZone
    ? new Date(dateObject.toLocaleString('en-US', { timeZone }))
    : dateObject;

  if (isToday(convertedDate)) {
    return `Today, ${formatDate(convertedDate, 'p')}`;
  }
  if (isTomorrow(convertedDate)) {
    return `Tomorrow, ${formatDate(convertedDate, 'p')}`;
  }
  if (isThisWeek(convertedDate)) {
    return `${formatDate(convertedDate, 'EEEE')}, ${formatDate(convertedDate, 'p')}`;
  }
  return `${formatDate(convertedDate, 'MMMM d')}, ${formatDate(convertedDate, 'p')}`;
};

export const humanReadable = ({ datetime, timezone }: { datetime: string; timezone?: string | undefined | null }): string => {
  const asDate = new Date(datetime);
  // Friday, August 28th @ 10:00 AM MDT • Join

  const datePart = asDate.toLocaleDateString(
    'default',
    {
      day: 'numeric',
      month: 'long',
      timeZone: timezone || undefined,
      weekday: 'long',
      // timeZoneName: 'short',
    },
  );

  const timePart = asDate.toLocaleTimeString(
    'default',
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timezone || undefined,
      timeZoneName: 'short',
    },
  );

  return `${datePart} @ ${timePart}`;
};
