const nameToInitials = (name?: string | null): string => {
  if (!name) {
    return '';
  }
  const lettersArr = name.trim().split(/\s+/).map(word => word.charAt(0));
  let letters = '';
  if (lettersArr.length === 1) {
    const [char0] = lettersArr;
    letters = char0;
  } else {
    letters = `${lettersArr[0]}${lettersArr[lettersArr.length - 1]}`;
  }
  return letters;
};

export default nameToInitials;