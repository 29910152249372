import { StepType, WORKFLOW_ACTIONS, WorkflowStep } from '@typings/api-models';

type ThisWorkflow = {
  steps: Pick<WorkflowStep, 'type' | 'eventName'>[];
};

const actionSubtext = (workflow: ThisWorkflow): string => {
  const actionStep = workflow.steps.find((step) => step.type === StepType.ACTION);
  if (actionStep?.eventName === WORKFLOW_ACTIONS.SEND_MESSAGE) {
    return 'Send message';
  }
  if (actionStep?.eventName === WORKFLOW_ACTIONS.SEND_EMAIL) {
    return 'Send email';
  }
  return 'Add action';
};

export default actionSubtext;
