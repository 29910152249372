import { WorkflowStep, WORKFLOW_ACTIONS } from '@typings/api-models';

type ThisWorkflowStep = Pick<WorkflowStep, 'eventName'>;

const actionText = (actionStep: ThisWorkflowStep): string => {
  if (actionStep.eventName === WORKFLOW_ACTIONS.SEND_MESSAGE) {
    return 'Send Message';
  }
  return 'Action Type';
};

export default actionText;
