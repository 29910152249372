import * as ApiModels from '@typings/api-models';

const isAttachmentHidden = ({ attachment, creatorPersonId, customerId, personId }: {
  attachment: Pick<ApiModels.MessageAttachment, 'id' | 'referenceId' | 'referenceType' | 'canAccess'> & {
    assigneeIds: string[];
  };
  customerId: string | null;
  personId: string | null;
  creatorPersonId: string | null;
}): boolean => {
  if (personId === customerId) {
    return attachment.referenceType !== ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER
      && attachment.referenceType !== ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE
      && attachment.referenceType !== ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT
      && !attachment.assigneeIds.includes(customerId as string) && attachment.assigneeIds.includes(creatorPersonId as string);
  }
  return false;
};

export default isAttachmentHidden;
